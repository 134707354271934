.signUpDropdown {
  height: 56px;
  > div {
    height: 100%;

    > div {
      height: 100%;

      > div {
        height: 100%;

        > div {
          height: 100%;

          button {
            height: 100%;
          }
        }
      }
    }
  }
}
